import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const SolutionsPage = ({ active = `solutions` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | Human Resources Management" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-black"></div>
    <div className="max-w-[1200px] mx-auto -mt-44">
      <p className="text-myblue text-3xl lg:text-4xl pb-4 pl-6 font-tbold">
        Human Resources Management
      </p>
      <StaticImage
        src="../images/image-9.jpg"
        alt="Decorative image of a healthy workplace team"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">Solutions</h3>
        <p className="text-lg md:text-xl mb-6 text-gray-800 text-left">
          We work with organizational leaders to foster, promote, and maintain a
          Psychologically Healthy Workplace. Some additional areas include
          Employee Financial Well-being, Employee Engagement, Employee
          Communication, Well-being at Work, Stress in the workplace,
          Occupational Health, Culture, Leadership, People Management, Values
          and Principles, and more.
        </p>
        <div>
          <p className="text-left text-mypurple text-lg font-bold">
            An overview:
          </p>
          <ul className="text-xl md:text-2xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
            <li className="mt-4">
              Workplace Needs Assessment Employee Research
            </li>
            <li className="mt-4">Strategic Human Resource Management</li>
            <li className="mt-4">Job Redesign</li>
            <li className="mt-4">Recruitment and Selection</li>
            <li className="mt-4">Onboarding and Induction</li>
            <li className="mt-4">Training and Development</li>
            <li className="mt-4">Employee Engagement and Motivation</li>
            <li className="mt-4">Talent Management</li>
            <li className="mt-4">Performance Management</li>
            <li className="mt-4">Rewards Management</li>
            <li className="mt-4">Policy and Procedures Development</li>
            <li className="mt-4">Succession Planning</li>
            <li className="mt-4">Workshops, Seminar, Lunch and Learn</li>
            <li className="mt-4">
              Customized HRM Services to meet organizational needs.
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-myblue max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-myblue shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/g320ycpvCto"
          title="Video"
        ></iframe>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/image-7.jpg"
          alt="Decorative image depicting the word wellbeing"
          className="h-full"
        />
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/employeeWellness.jpg"
          alt="Decorative image depicting the a wellness program"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default SolutionsPage
